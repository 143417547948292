import React from 'react';
import SubHeader from '../../Sub-Header/Sub-Header';
import Carousel from './Carousel.jsx';
import styles from './Not-Found.module.css';

import '../../../App.css';

const reel = {
    "image1": './images/choc-cake.webp',
    "image2": './images/coffee-cardamom.webp',
    "image3": './images/coffee-cardamom2.webp',
    "image4": './images/find-left.webp',
    "image5": './images/find-middle.webp',
    "image6": './images/find-right.webp',
    "image7": './images/choc-croissant.webp',
    "image8": './images/pre-croissants.webp',
    "image9": './images/Sticky-date.webp',
    "image10": './images/story-left.webp',
    "image11": './images/story-middle.webp',
    "image12": './images/story-right.webp',
}

const reelAlt = { 
    "image1Alt": "Chocolate Cake",
    "image2Alt": "Coffee Cardamom Scroll",
    "image3Alt": "Coffee Cardamom Cruffin",
    "image4Alt": "Gluten Free Carrot Cake",
    "image5Alt": "A tray of croissants",
    "image6Alt": "Berry Danish",
    "image7Alt": "Chocolate Croissant",
    "image8Alt": "A tray of pre-baked croissants",
    "image9Alt": "Sticky date pudding cake with a coconut caramel crown",
    "image10Alt": "Choc caramel tart & Apricot Caramel tart",
    "image11Alt": "Cross section of croissant",
    "image12Alt": "Apricot and Cream danish",
}

export default function NotFound() {   
    return (
        <>
            <SubHeader>
                <div className={styles.subheader}>
                    <h1>Uh Oh - That page isn't a page!</h1>
                    <p className={styles.p}>While you're here, you might as well check out some other delicious goodies</p>
                </div>
            </SubHeader>
            <div className="page-container">
                <div className='desktop-padding' />
                    <div className={styles.container}>
                        <Carousel 
                            images={reel}
                            alt={reelAlt}
                        />
                    </div>
                <div className='desktop-padding' />
            </div>
        </>
    )
}
