import React from 'react';
import '../../../App.css';
import styles from './Story.module.css';
import ImageContainer from '../../PageStyling/ImageContainer';

const selfPortrait = require('./images/Michael.png');

const storyReel = {
    "image1": require('./images/story-left.webp'),
    "image2": require('./images/story-middle.webp'),
    "image3": require('./images/story-right.webp'),
}

const altImage = { 
    "image1Alt" : "Gluten Free tarts",
    "image2Alt" : "Cross section of a croissant",
    "image3Alt" : "Apricot & cream pastry",
}

function Story() { 
    return(
        <div className='page-container' style={{marginTop: 10}}>
            <div className='desktop-padding' />
                <div className='page'>
                    <hr style={{marginTop: 15}} />

                    <ImageContainer reel={storyReel} alt={altImage} />

                    <hr />

                    <section className='text-container'>
                        <div className={styles['text-box-story']}>
                            <p>
                            Looking all the way back, I gained my first baking apprenticeship with my beloved 
                            and ever patient mother. I was the nuisance assistant, constantly dipping fingers 
                            into mixing bowls and clawing for batter coated beater paddles or Tupperware spatulas.
                            </p><p>
                            As time elapsed, I began favouring savoury creations. 
                            In the realm of baking this became loaves and flatbreads, 
                            slathered and sprinkled to be torn and shared.
                            </p><p>
                            With a move in recent years I stumbled upon a role as a baker and an opportunity 
                            to learn as an apprentice. With ample bread and croissants, I also found an unexpected 
                            delight in creating cakes and pastries, likely energised by the reminiscence of my 
                            childhood kitchen experience. So began the exploration of the 'why' allowing me to uncover 
                            the 'how' in order to transform all that is familiar, into plant based elaborations. 
                            Gluten free also became a focus to include another group who so often are faced with only 
                            ogling a spread of treats and unable to do more than drool.
                            </p><p>
                            These goals coalesced into the idea of sharing with a larger audience. Ensuring that the 
                            ingredients going into each pastry are as good as what comes out. The joy of creating 
                            and sharing tasty things is what I benefit most from this endeavour. As such, I saw an 
                            equal opportunity to do good by sharing a benefit to others who are in need.
                            </p><p>
                            I hope this vision will act in small part to distribute resources and well-being beyond 
                            our small part of the world. Where for some of us it all comes rather simply, but for others, 
                            even a little more can make a large difference.
                            </p>
                            <p style={{marginBottom:10}}>- Michael</p>          
                        </div>
                        <div className={styles['portrait']}>
                            <img src={selfPortrait} alt="Good Fat Pastry owner Michael" />
                        </div>   
                    </section>
                </div>
            <div className='desktop-padding' />
        </div>
    );
};

export default Story; 