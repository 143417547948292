import React, { useState, useEffect, useCallback } from 'react';
import styles from './Not-Found.module.css';

export default function Carousel({ images, alt }){
    const [activeIndex, setActiveIndex] = useState(0);
    const imageKeys = Object.keys(images);
    const altKeys = Object.keys(alt);

    const nextSlide = useCallback(() => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % imageKeys.length);
    }, [imageKeys.length]);

    const prevSlide = () => {
        setActiveIndex((prevIndex) => (prevIndex - 1 + imageKeys.length) % imageKeys.length);
    };

    useEffect(() => {
        const timeout = setTimeout(nextSlide, 4000);
        return () => clearTimeout(timeout)
    }, [activeIndex, nextSlide])

    return (
        <div className={styles.carousel}>
            <button 
                className={`${styles.carouselArrow} ${styles.right}`} 
                onClick={prevSlide}
            >
                &#10094;
            </button>
            <div className={styles.carouselSlides}>
                {imageKeys.map((key, index) => (
                    <img
                        key={key}
                        className={`${styles.carouselSlide} ${index === activeIndex ? styles.active : ''}`}
                        src={require(`${images[imageKeys[index]]}`)}
                        alt={alt[altKeys[index]]}
                    />
                ))}
            </div>
            <button 
                className={`${styles.carouselArrow} ${styles.left}`} 
                onClick={nextSlide}
            >
                &#10095;
            </button>
            
        </div>
    );
}